@import '../../theme.less';

.article-list {
    .article {
        background-color: @content_bg_color;
        margin-bottom: 15px;
        > .title {
            padding: 15px;
            font-size: 18px;
            font-weight: bold;
            cursor: pointer;
        }
        > .info {
            display: flex;
            padding: 0 15px 15px;
            font-size: 13px;
            color: rgba(0, 0, 0, 0.3);
            gap: 10px;
            > .operation {
                cursor: pointer;
            }
        }
        > .summary {
            padding: 15px;
            font-size: 14px;
            margin-bottom: 15px;
        }
    }
}
