@import '../../theme.less';

@button_bg: rgba(0, 0, 0, 0.08);
@button_disabled_bg: rgba(0, 0, 0, 0.05);
@button_active_bg: rgba(0, 0, 0, 0.15);
@button_hover_bg: @button_active_bg;
@button_active_font: @color_white;
@font_color: rgb(200, 200, 200);
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    font-size: 13px;
    > .quick-jumper {
        display: flex;
        align-items: center;
        justify-content: center;
        > .ellipsis,
        .pagination-btn {
            width: 36px;
            height: 36px;
            line-height: 36px;
            background-color: @button_bg;
            text-align: center;
        }
    }
    > .page-number-jumper {
        display: flex;
        align-items: center;
        justify-content: center;
        > input {
            height: 36px;
            width: 36px;
            background-color: @button_bg;
            border: none;
            border-right: 1px solid rgba(255, 255, 255, 0.5);
            outline: none;
            padding: 0 10px;
        }
        > span {
            height: 36px;
            line-height: 36px;
            padding: 0 5px;
            cursor: pointer;
            background-color: @button_bg;
            &:hover {
                background-color: @button_hover_bg;
            }
        }
    }
    > .page-size-selector {
        .select-control {
            background-color: @button_bg;
            border: none;
            box-shadow: none;
        }
        .select-dropdown-indicator {
            > svg {
                fill: #999;
            }
        }
    }
    button {
        height: 36px;
        border: none;
        outline: none;
        white-space: nowrap;
        line-height: 1;
        background-color: @button_bg;
        &:disabled {
            background-color: @button_disabled_bg;
        }
    }
    button:not([disabled]) {
        cursor: pointer;
        &.active,
        &:hover {
            background-color: @button_active_bg;
            color: @button_active_font;
        }
    }
}
